import React, { ReactElement } from 'react'
import { Text } from '@mindfulchefuk/design-system'
import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'
import { CTALocation } from '@mindfulchefuk/analytics'
import { HomepageSectionContainer } from '@mindfulchefuk/features/Homepage/components/HomepageSectionContainer'

type TBottomPageCTASectionProps = {
  ctaLocation?: CTALocation
}

export const BottomPageCTASection = ({
  ctaLocation = 'homepage bottom of the page CTA',
}: TBottomPageCTASectionProps): ReactElement => {
  return (
    <HomepageSectionContainer
      as="section"
      zIndex={1}
      maxWidth="800px"
      px={24}
      mx="auto"
      textAlign="center"
      position="relative"
      data-testid="bottom-page-cta-section"
      id="homepage-bottom-page-cta-section"
    >
      <EmphasisLinebreakText
        pb={16}
        as="h2"
        variant={{ base: 'heading-sm', sm: 'heading-md' }}
        mx="auto"
        maxWidth="500px"
        text="Reduce food waste, save time. Healthy eating made easy starts here"
        emphasisText="Healthy eating made easy"
        emphasisVariant={{ base: 'heading-md', sm: 'heading-lg' }}
        id="homepage-bottom-page-cta-copy"
      />

      <CTA
        data-testid="homepage-hero-cta"
        analytics={{
          category: ANALYTICS_CATEGORIES.homepage,
          action: 'Bottom of the page CTA clicked',
          label: 'Choose recipe button clicked',
        }}
        source={ctaLocation}
        showCopy={false}
        id="homepage-bottom-page-cta-button"
      />
      <Text
        variant={{ base: 'body-sm', sm: 'body-md', md: 'body-lg' }}
        maxWidth="270px"
        mx="auto"
        pt={12}
        id="homepage-bottom-page-cta-flexible-subscription-copy"
      >
        Fully flexible subscription, skip or pause anytime
      </Text>
    </HomepageSectionContainer>
  )
}
