import React, { ReactElement } from 'react'
import { Box, Image } from '@mindfulchefuk/design-system'

export type THeroStaticImage = {
  heroImage: string
  imageWidth: number
  imageHeight: number
}

export const HeroStaticImage = ({
  heroImage,
  imageHeight,
  imageWidth,
}: THeroStaticImage): ReactElement => (
  <Box
    id="hero-image-container"
    maxHeight={768}
    width={{ md: '50%' }}
    overflow="hidden"
  >
    <Image src={heroImage} width={imageWidth} height={imageHeight} />
  </Box>
)
