import React, { ReactElement } from 'react'
import { Box, Text } from '@mindfulchefuk/design-system'
import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { useSelector } from 'react-redux'
import { RootState } from '@mindfulchefuk/types/store'
import { getDiscountConfig } from '@mindfulchefuk/utils/getDiscountConfig'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'
import { CTALocation } from '@mindfulchefuk/analytics'
import { homepageVariantFallback } from '@mindfulchefuk/features/Homepage/data/homepage-variant'
import { genericDiscountCopy } from '@mindfulchefuk/config/coreDiscountsConfig'

type THeroBottomSectionProps = {
  ctaText?: string
  ctaTextEmphasis?: string
  ctaLocation?: CTALocation
  additionalContent?: ReactElement
}

export const HeroBottomSection = ({
  ctaText = homepageVariantFallback.heroQuote,
  ctaTextEmphasis = homepageVariantFallback.emphasisInHeroQuote,
  ctaLocation = 'homepage hero plates CTA',
  additionalContent = null,
}: THeroBottomSectionProps): ReactElement => {
  const savedVoucherCode = useSelector(
    (state: RootState) => state.basket.savedVoucherCode
  )
  const discountConfig = getDiscountConfig(savedVoucherCode)

  return (
    <Box
      width="100%"
      textAlign="center"
      pt={{ base: 16, sm: 0 }}
      pb={32}
      px={8}
      data-testid="hero-bottom-section"
      mt={{ base: 28, md: 0 }}
    >
      {additionalContent && additionalContent}
      <EmphasisLinebreakText
        as="p"
        text={ctaText}
        variant={{ base: 'body-lg-500', sm: 'body-xl-500' }}
        emphasisText={ctaTextEmphasis}
        emphasisVariant={{ base: 'body-xl-500', sm: 'body-xxl-500' }}
        id="homepage-hero-flexible-subheading"
      />

      <Box mt={12}>
        <CTA
          analytics={{
            category: ANALYTICS_CATEGORIES.homepage,
            action: 'Hero CTA button clicked',
            label: 'Choose Recipes',
          }}
          source={ctaLocation}
          showCopy={false}
          id="homepage-hero-choose-recipe-CTA"
        />
        <Text as="p" variant="body-md" mt={8} id="homepage-hero-discount-text">
          {discountConfig
            ? `Get ${discountConfig.totalDiscountSentence}`
            : genericDiscountCopy}
        </Text>
      </Box>
    </Box>
  )
}
