export const expChooseDeliveryOption = 'exp-choose-delivery-option'

export const expOnboardingRecipeCardRedesign =
  'exp-onboarding-recipe-card-redesign'

export const expLoggedInRecipeCardRedesign =
  'exp-logged-in-recipe-card-redesign'

export const expUpsellModalWithCategories = 'exp-upsell-modal-categories'

export const flagChooseRecipesOnboardingRefactor =
  'flag-choose-recipes-refactor-onboarding'

export const expStaticHomepageHero = 'exp-static-homepage-hero'
