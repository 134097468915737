import React, { ReactElement } from 'react'
import { Box } from '@mindfulchefuk/design-system'

export type THeroVideo = {
  heroVideo: string
}

export const HeroVideo = ({ heroVideo }: THeroVideo): ReactElement => {
  return (
    <Box backgroundColor="salt">
      <video
        aria-label="plates of food showing Mindful Chef benefits including no refined carbs and all gluten free recipes. Another claim to show Mindful Chef are a one feeds two partner "
        autoPlay
        muted
        loop
        width="100%"
        height="100%"
        style={{ objectFit: 'cover' }}
      >
        <source src={heroVideo} type="video/mp4" data-testid="video-source" />
      </video>
    </Box>
  )
}
