import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { JustRecipe } from '@mindfulchefuk/features/Recipes/interfaces'
import {
  getRecipes,
  TGetRecipesParams,
} from '@mindfulchefuk/features/Recipes/services/getRecipes'
import { STALE_1_HOUR } from '@mindfulchefuk/constants'

export const useGetJustRecipes = <TData = JustRecipe[]>(
  params: TGetRecipesParams,
  options: UseQueryOptions<JustRecipe[], unknown, TData> = {}
) =>
  useQuery({
    queryKey: ['recipes', params],
    queryFn: () => getRecipes(params),
    enabled: !!params.recipeIds?.length && !!params?.portionCount,
    staleTime: STALE_1_HOUR,
    ...options,
  })
