import React, { ReactElement, useEffect, useRef } from 'react'
import { Box } from '@mindfulchefuk/design-system'
import { useHasClientHydrated } from '@mindfulchefuk/hooks/useHasClientHydrated'
import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { useTrustPilotClickEvent } from '@mindfulchefuk/features/Homepage/hooks/useTrustPilotClickEvent'
import { CTALocation } from '@mindfulchefuk/analytics'
import { HomepageSectionContainer } from '@mindfulchefuk/features/Homepage/components/HomepageSectionContainer'
import { EmphasisLinebreakText } from './EmphasisLinebreakText'

type TTrustPilot = {
  dataTags?: string
  ctaLocation?: CTALocation
  title?: string
  titleEmphasis?: string
  subtitle?: string
  subtitleEmphasis?: string
}
export const TrustpilotCarousel = ({
  dataTags,
  ctaLocation,
  title = 'Over 30 million meals delivered to more than 400,000 happy customers.',
  titleEmphasis,
  subtitle = null,
  subtitleEmphasis,
}: TTrustPilot): ReactElement => {
  const isClient = useHasClientHydrated()
  const ref = useRef(null)
  // If window.Trustpilot is available it means that we need to load the
  // TrustPilotSlider from our ref. If it's not, it means the script you pasted
  // into <head /> isn't loaded just yet. When it is, it will automatically load
  // the TrustPilotSlider.
  useEffect(() => {
    if (isClient && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [isClient])

  useTrustPilotClickEvent({
    widgetName: 'carousel',
    eventAction: 'Trust Pilot Clicked',
    eventLabel: 'Rating clicked',
  })

  return (
    <HomepageSectionContainer
      as="section"
      id="homepage-trustpilot-carousel-section"
      textAlign="center"
      px={{ base: 12, sm: 24 }}
      maxWidth="1236px"
      mx="auto"
    >
      <EmphasisLinebreakText
        as="h1"
        text={title}
        variant={{ base: 'heading-sm', md: 'heading-md' }}
        maxWidth="724px"
        mb={12}
        mx="auto"
        emphasisText={titleEmphasis}
        emphasisVariant={{ base: 'heading-md', md: 'heading-lg' }}
        id="homepage-trustpilot-carousel-header"
      />

      {subtitle && (
        <EmphasisLinebreakText
          as="h2"
          text={subtitle}
          variant="body-lg"
          maxWidth="724px"
          mb={12}
          mx="auto"
          emphasisText={subtitleEmphasis}
          emphasisVariant="body-xl"
          id="homepage-trustpilot-carousel-subtitle"
        />
      )}

      {isClient && (
        <Box
          width="100%"
          ref={ref}
          className="trustpilot-widget"
          data-widget-name="carousel"
          data-locale="en-GB"
          data-template-id="54ad5defc6454f065c28af8b"
          data-businessunit-id="563ca4940000ff000585374b"
          data-style-height="240px"
          data-style-width="100%"
          data-theme="light"
          data-stars="4,5"
          data-review-languages="en"
          data-tags={dataTags}
          id="homepage-trustpilot-carousel"
        />
      )}
      <Box mt={{ base: 24 }} mb={24}>
        <CTA
          data-testid="homepage-hero-cta"
          analytics={{
            category: ANALYTICS_CATEGORIES.homepage,
            action: 'Trust Pilot CTA clicked',
            label: 'Choose recipes',
          }}
          source={ctaLocation}
          showCopy={false}
          id="homepage-trustpilot-carousel-button"
        />
      </Box>
    </HomepageSectionContainer>
  )
}
